import React from "react";
import {Box, ClickAwayListener, Fade, Link, Popper, Stack} from "@mui/material";
import MenuLink from "./Navigation/MenuLink";
import {config} from "../config.js";
import {isAnyAdmin, isDistributor, isNotAnyAdmin, isNotDistributor} from "../Utilities/UserDataFunctions";

import buttonBar from "../Images/OBO1/buttonbar_bkg.gif";
import homeButton from "../Images/OBO1/menubar_btn_Home.gif";
import adminButton from "../Images/OBO1/menubar_btn_Admin_0.gif";
import forecastButton from "../Images/OBO1/menubar_btn_Forecast_0.gif"
import orderBeerButton from "../Images/OBO1/menubar_btn_OrderBeer_0.gif"
import orderSearchButton from "../Images/OBO1/menubar_btn_OrderSearch_0.gif"
import planningButton from "../Images/OBO1/menubar_btn_Planning_0.gif"
import shipmentsButton from "../Images/OBO1/menubar_btn_Shipments_0.gif"
import informationButton from "../Images/OBO1/menubar_btn_Information_0.gif"
import orderChangeButton from "../Images/OBO1/menubar_btn_OrderChange_0.gif"
import onTopRequestsButton from "../Images/OBO1/menubar_btn_OnTopReq_0.gif"
import ticketsButton from "../Images/OBO1/menubar_btn_Tickets_0.gif"
import messagesButton from "../Images/OBO1/menubar_btn_Message_0.gif"
import profileInfoButton from "../Images/OBO1/menubar_btn_ProfileInfo_0.gif"
import logoutButton from "../Images/OBO1/menubar_btn_LogOut.gif"
import {navBarButtonStyle, navBarCenterButtonStyle, OboLink} from "../Styles/Styles";
import {useUserDataContext} from "../Contexts/userdataContext";
import PlanningWindow from "./PlanningWindow";
import {usePlanningWindowContext} from "../Contexts/planningWindowsContext";

const homeUrl = config.OBO_URL + "/default.aspx";
const orderBeerUrl = config.OBO_URL + "/OrderBeer/order_beer_overview.aspx"
const orderSearchUrl = config.OBO_URL + "/Order/order_search.aspx"
const shipmentsUrl = config.OBO_URL + "/MonthInReview/month_in_review.aspx"
const informationUrl = config.OBO_URL + "/Report/report_select.aspx"
const orderChangeRequestsUrl = config.OBO_URL + "/OrderChangeRequest/change_search.aspx?OrderChangeRequestType=1"
const onTopRequestsUrl = config.OBO_URL + "/OrderChangeRequest/change_search.aspx?OrderChangeRequestType=3"
const ticketsUrl = config.OBO_URL + "/Ticket/ticket_search.aspx"
const messagesUrl = config.OBO_URL + "/Messaging/messaging.aspx"
const profileInfoUrl = config.OBO_URL + "/Distributor/profile.aspx"
const logoutUrl = config.OBO_URL + "/logout.aspx"

export default function NavBar() {
    const {userData} = useUserDataContext();
    const {planningWindows} = usePlanningWindowContext()
    const [adminOpen, setAdminOpen] = React.useState(false);
    const [forecastOpen, setForecastOpen] = React.useState(false);
    const [planningOpen, setPlanningOpen] = React.useState(false);
    const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
    const [forecastAnchorEl, setForecastAnchorEl] = React.useState(null);
    const [planningAnchorEl, setPlanningAnchorEl] = React.useState(null);

    const handleClickAway = () => {
        setAdminOpen(false);
        setForecastOpen(false);
        setPlanningOpen(false);
    }
    const handleMouseOver = (event, menu) => {
        switch (menu) {
            case 'admin':
                setAdminAnchorEl(event.currentTarget);
                setAdminOpen(true);
                setForecastOpen(false);
                setPlanningOpen(false);
                break;
            case 'forecast':
                setForecastAnchorEl(event.currentTarget)
                setAdminOpen(false);
                setForecastOpen(true);
                setPlanningOpen(false);
                break;
            case 'planning':
                setPlanningAnchorEl(event.currentTarget)
                setAdminOpen(false);
                setForecastOpen(false);
                setPlanningOpen(true);
                break;
        }
    };

    const canAdminBeOpen = adminOpen && Boolean(adminAnchorEl);
    const canForecastBeOpen = forecastOpen && Boolean(forecastAnchorEl);
    const canPlanningBeOpen = planningOpen && Boolean(planningAnchorEl);

    const adminId = canAdminBeOpen ? 'transition-popper' : undefined;
    const forecastId = canForecastBeOpen ? 'transition-popper' : undefined;
    const planningId = canPlanningBeOpen ? 'transition-popper' : undefined;

    return (<ClickAwayListener onClickAway={handleClickAway}>
        <div
            style={{
                height: "19px", backgroundImage: `url(${buttonBar})`, backgroundPosition: 'center center', backgroundRepeat: 'repeat-x',
            }}>
            <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                <Link href={homeUrl}
                      style={{
                          ...navBarButtonStyle, backgroundImage: `url(${homeButton})`, width: "49px",
                      }}>
                </Link>
                <Stack direction="row" justifyContent="center">
                    {isAnyAdmin(userData) ? <>
                        <button onMouseOver={(event) => handleMouseOver(event, "admin")} aria-describedby={adminId}
                                style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${adminButton})`}}>
                        </button>
                        <Popper id={adminId} open={adminOpen} anchorEl={adminAnchorEl} placement="bottom-start" transition>
                            {({TransitionProps}) => (<Fade {...TransitionProps} timeout={350}>
                                <Box sx={{border: 1, p: 1, bgcolor: 'background.paper'}}>
                                    <Stack direction="column">
                                        <MenuLink id="AdminDashboard"/>
                                        <MenuLink id="AdminWindowManagement"/>
                                        <MenuLink id="AdminProductSettings"/>
                                        <MenuLink id="AdminProductAvailability"/>
                                        <MenuLink id="AdminDistributorManagement"/>
                                        <MenuLink id="AdminShipmentsManagement"/>
                                        <MenuLink id="AdminMessageManagement"/>
                                        <MenuLink id="AdminFeedSenderManagement"/>
                                        <MenuLink id="AdminUserDistributorMap" />
                                        <MenuLink id="AdminFulfillmentStatus" obo2/>
                                        <MenuLink id="AdminAdHocWindowManagement" lock/>
                                        <MenuLink id="AdminItemManagement" lock/>
                                        <MenuLink id="AdminIndirectDistributorsList"/>
                                    </Stack>
                                </Box>
                            </Fade>)}
                        </Popper></> : null}
                    <button onMouseOver={(event) => handleMouseOver(event, "forecast")} aria-describedby={forecastId}
                            style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${forecastButton})`}}>
                    </button>
                    <Popper id={forecastId} open={forecastOpen} anchorEl={forecastAnchorEl} placement="bottom-start" transition>
                        {({TransitionProps}) => (<Fade {...TransitionProps} timeout={350}>
                            <Box sx={{border: 1, p: 1, bgcolor: 'background.paper'}}>
                                <Stack direction="column">
                                    <MenuLink id="ForecastReview" obo2/>
                                    {isAnyAdmin(userData) ? <MenuLink id="ForecastAdjustments" lock/> : null}
                                </Stack>
                            </Box>
                        </Fade>)}
                    </Popper>
                    <Link href={orderBeerUrl}
                          style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${orderBeerButton})`}}>
                    </Link>
                    <Link href={orderSearchUrl}
                          style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${orderSearchButton})`}}>
                    </Link>
                    <Link href={shipmentsUrl}
                          style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${shipmentsButton})`}}>
                    </Link>
                    <button onMouseOver={(event) => handleMouseOver(event, "planning")} aria-describedby={planningId}
                            style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${planningButton})`}}>
                    </button>
                    <Popper id={planningId} open={planningOpen} anchorEl={planningAnchorEl} placement="bottom-start" transition>
                        {({TransitionProps}) => (<Fade {...TransitionProps} timeout={350}>
                            <Box sx={{border: 1, p: 1, bgcolor: 'background.paper'}}>
                                <Stack direction="column">
                                    {!(planningWindows && planningWindows.length > 0) && (isDistributor(userData) || isNotAnyAdmin(userData)) ?
                                        <p style={OboLink}>No Planning Windows Are Available</p> : null}
                                    <PlanningWindow/>
                                    {isNotDistributor(userData) ? <>
                                        <MenuLink id="PlanningSubmissionReport"/>
                                        <MenuLink id="PlanningComparisonReport"/>
                                        <MenuLink id="PlanningRollupReport"/></> : null}
                                    {isAnyAdmin(userData) ? <>
                                        <MenuLink id="PlanningWindowManagement" lock/>
                                        <MenuLink id="PlanningDepletionImport" lock/>
                                        <MenuLink id="PlanningPlanningPeriods" lock/></> : null}
                                </Stack>
                            </Box>
                        </Fade>)}
                    </Popper>
                    <Link href={informationUrl}
                          style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${informationButton})`}}>
                    </Link>
                    {isDistributor(userData) ? <>
                        <Link href={orderChangeRequestsUrl}
                              style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${orderChangeButton})`}}>
                        </Link>
                        <Link href={onTopRequestsUrl}
                              style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${onTopRequestsButton})`}}>
                        </Link>
                        <Link href={ticketsUrl}
                              style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${ticketsButton})`}}>
                        </Link>
                    </> : null}
                    <Link href={messagesUrl}
                          style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${messagesButton})`}}>
                    </Link>
                    <Link href={profileInfoUrl}
                          style={{...navBarButtonStyle, ...navBarCenterButtonStyle, backgroundImage: `url(${profileInfoButton})`}}>
                    </Link>
                </Stack>
                <Link href={logoutUrl}
                      style={{
                          ...navBarButtonStyle, backgroundImage: `url(${logoutButton})`, width: "49px",
                      }}>
                </Link>
            </Stack>
        </div>
    </ClickAwayListener>)
}